.compte-rendu {
    font-family: "Red Hat Display", sans-serif !important;
    .logo-compte-rendu
{
    padding-left: 1em;
    padding-right: 1em;
}

.image-couverture-compte-rendu
{
    margin-top: 100px;
    margin-bottom: 100px;
}

h1{
    font-size: 38px;;
}

h4{
    font-size: 22px;;
}
p{
    font-size: 16px;
   
}

.sticky-top {
    position: fixed;
    top: 0;
    z-index: 1020;
    right: 10px;
}

img{
    max-height: 400px;
}

.separateur
{
    height: 1px;
    background-color: #969696;
    margin-top: 50px;
    margin-bottom: 50px;
}

.info-client{
    
    font-size: 30px;
}

.titre-situation{
    font-weight: bold;
    font-size: 22px;
    margin-top: 20px;
}

.page-to-print{
    width: 718px;
    height : 971px;
    margin-left: auto;
    margin-right: auto;
}

.titre-compte-rendu
{
    font-family: "Poppins", sans-serif;
}
}