.App-logo{
  width: 150px;
}

.app-container{
  width: 100%;
  margin: 0;
  max-width: none;
  background-color: #F5F7F9;
  height: 100vh;
  color: #3D546F !important;
}

.light-grey{
  background-color: #F5F7F9;
}

table, .sidebar, .bloc{
  background-color: white;
}

.bloc{
  margin-bottom: 20px;
  padding: 20px;
}

table, .bloc{
  border-radius: 5px;
}

table{
  text-align: center;
}

.sidebar{
  height: 100vh;
  color : #7f8893 !important;
  width: 250px;

  .nav-link{
    margin-left: 1rem;
  }

  a{text-transform: none !important;
    font-size: 15px !important;
    font-family: "Roboto", sans-serif;
    font-weight: 400 !important;
    color: #72787f !important;
    text-align: left;
    
  }
i{
  margin-right: 20px;
}


}


@media (min-width: 1000px) { 
  .menu-mobile{display: none !important;}
}

@media (max-width: 1000px) { 
  .sidebar{display: none !important;}
  .app-container{ margin-top: 50px;}
  
}

.menu-mobile
{ 
  img{width: 80px;}
  a{cursor: pointer;
    text-transform: none !important;
    font-size: 15px !important;
    font-family: "Roboto", sans-serif;
    font-weight: 400 !important;
    color: #72787f !important;
    text-align: left;}
}
i{
  margin-right: 20px;
}

.table-container{
  overflow-x: scroll;
}


p{margin-bottom: -0.5rem;}

.custom-badge{
  border-radius: 15px;
  color: white;
  font-weight: 500;
  margin-bottom: 5px;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.8em;
  display: inline-block;
  margin: 0.2em;
}

.card{margin-bottom: 1em;}




