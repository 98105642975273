.stats{
    .stat{
        font-weight: 500;
    font-size: 20px;
    .stat-number{
        width: 40px;
    background-color: #3B71CA;
    text-align: center;
    color: white;
    margin-right: 5px;
    border-radius: 50px;
    height: 40px;
    padding-top: 5px;
    display: inline-block;
    }
}
        
    
}

.position-sticky {

    bottom: 0;
}