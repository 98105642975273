.QRCodeGenerateur {canvas{height: 75.6px !important;width: 75.6px !important;}
p{font-size: 8px;
    display: block;
    margin-left: auto;
    margin-right: auto;
color: #2290CF;
width: 90px;
margin-bottom: 0px;

}
.qrcode{width: 240px;}
.page{width: 680px;}}